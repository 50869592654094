<script lang="ts" setup>
import type { PresetPackagePopupData } from "@/types";

const props = defineProps<{
	offer: PresetPackagePopupData;
}>();
const { t } = useT();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const loginGuard = useLoginGuard();
const { open } = useFunrizeModals();

const badgeLabel = computed(() => parseJSON(props.offer?.promoOfferPreset?.badgeLabel || "{}"));

const handleOpenCash = () => {
	loginGuard({
		success: () => {
			window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${props.offer?.promoOfferPreset?.id}/promoOffer/`);
			dispatchGAEvent({
				event: "click_button",
				location: "tooltip",
				button_name: "make_deposit"
			});
			window?.$store?.gaCash?.deposit?.({
				location: "tooltip",
				step: "select_payments_package",
				package: props.offer?.money
			});
			window?.$store?.gaCash?.deposit?.({
				location: "tooltip",
				step: "view_payments_info",
				package: props.offer?.money
			});
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<div v-if="offer" class="banner" @click="handleOpenCash()">
		<NuxtImg
			class="banner-img"
			:src="`${baseImageUrl}${offer?.promoOfferPreset?.imageMenu}`"
			format="webp"
			alt="promo"
		/>
		<ACornerBadge v-if="badgeLabel?.percent || badgeLabel?.text" variant="accent" modifiers="right" class="top-right">
			<AText v-if="badgeLabel.percent" variant="trieste" :modifiers="['bold']">{{ badgeLabel.percent }}</AText>
			<AText v-if="badgeLabel.text" variant="trieste" :modifiers="['uppercase', 'normal']">{{ badgeLabel.text }}</AText>
		</ACornerBadge>
		<ABadge v-if="offer?.promoOfferPreset?.bestDeal" background="var(--cebu)" variant="skew" class="top-left" autosize>
			<AText variant="tallinn" :modifiers="['bold', 'uppercase']">{{ t("Best deal") }}</AText>
		</ABadge>
		<ABadge
			v-if="offer?.promoOfferPreset?.mostPopular"
			background="var(--cabo)"
			variant="skew"
			class="top-left"
			autosize
		>
			<AText variant="tallinn" :modifiers="['bold', 'uppercase']">{{ t("Most popular") }}</AText>
		</ABadge>
		<div class="content">
			<MPrizeFund v-if="offer?.promoOfferPreset?.coins" variant="coins" img="nuxt-img/icons/coins.png" :icon-size="16">
				<AText class="text-coimbatore" variant="toledo" :modifiers="['bold']">
					{{ numberFormat(offer.promoOfferPreset.coins) }}
				</AText>
			</MPrizeFund>
			<MPrizeFund v-if="offer?.promoOfferPreset?.entries" variant="entries" icon="14/cash" :icon-size="16">
				<AText class="text-cixi" variant="toledo" :modifiers="['bold']">
					{{ numberFormat(offer.promoOfferPreset.entries) }}
				</AText>
			</MPrizeFund>
			<AButton class="button" variant="primary">
				<AText class="text-cannes" variant="topeka" :modifiers="['uppercase', 'semibold']">
					{{ t("Buy") }} ${{ offer?.money }}
				</AText>
			</AButton>
		</div>
	</div>
</template>

<style scoped lang="scss">
.banner {
	position: relative;
	overflow: hidden;
	width: 296px;
	height: 90px;
	border-radius: 10px;
	cursor: pointer;

	&-img {
		width: 100%;
	}

	.top-left,
	.top-right {
		--a-badge-skew-height: 15px;
		position: absolute;
		top: 0;
		left: 0;
	}

	.top-right {
		--a-corner-badge-default-width: 60px;
		--a-corner-badge-default-height: 60px;
		left: auto;
		right: 0;

		&.accent {
			--a-corner-badge-right-transform: translate(12px, -15px) rotate(41deg);
			&::before {
				height: 40px;
				transform: translate(0px, -16px) rotate(40deg);
			}
		}
	}

	.content {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		height: 100%;
		margin-left: gutter(16.875);

		.button {
			height: 27px;
			margin-top: gutter(0.5);
			display: flex;
		}
	}
}
</style>
